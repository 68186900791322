<!--
 * @文档描述: 
 * @author: 宣灵杰
 * @Date: 2024-07-24 14:35:23
 * @LastEditTime: 2024-07-24 14:55:35
 * @LastEditors: 宣灵杰
-->
<template>
  <div id="app">
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Footer
  }
}
</script>

<style lang="scss">
html {
  background: #f7f7f7;
}
body {
  margin: 0;
  min-width: 1200px;
  background: #fff;
}
</style>
